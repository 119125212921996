/*
 * Hook to manage both normal basket and temp basket in checkout, so the logic is kept on place
 */

import { useCallback, useMemo } from 'react';
import { useBasket } from '~/store';
import { useTempBasket } from '~/store/temp-basket';

export const useCheckoutBasket = () => {
    const {
        basketId: regularBasketId,
        basket: regularBasket,
        updatingBasket: updatingRegularBasket,
        setShipping: setShippingRegularBasket,
        setClubMember: setClubMemberRegularBasket,
        setUseMemberPoints: setUseMemberPointsRegularBasket,
        addVoucher: addVoucherRegularBasket,
        clearVoucher: clearVoucherRegularBasket,
        updatingVoucher: updatingVoucherRegularBasket,
        memberPoints: regularMemberPoints,
        getMemberPointsForBasket: getMemberPointsForRegularBasket,
        hasBeenInitialized: hasRegularBasketBeenInitialized,
    } = useBasket();

    const {
        basketId: tempBasketId,
        basket: tempBasket,
        updatingBasket: updatingTempBasket,
        setShipping: setShippingTempBasket,
        tempBasketType,
        selectedStore: tempBasketStore,
        selectedDeliveryZipCode: tempBasketDeliveryZipCode,
        setClubMember: setClubMemberTempBasket,
        setUseMemberPoints: setUseMemberPointsTempBasket,
        addVoucher: addVoucherTempBasket,
        clearVoucher: clearVoucherTempBasket,
        updatingVoucher: updatingVoucherTempBasket,
        memberPoints: tempMemberPoints,
        getMemberPointsForBasket: getTempMemberPointsForBasket,
        hasBeenInitialized: hasTempBasketBeenInitialized,
    } = useTempBasket();

    const isTempBasketAvailable = useMemo(() => !!tempBasket && !!tempBasketType, [tempBasket, tempBasketType]);
    const basketId = useMemo(() => tempBasketId ?? regularBasketId, [tempBasketId, regularBasketId]);

    const hasBeenInitialized = useMemo(
        () => hasTempBasketBeenInitialized && hasRegularBasketBeenInitialized,
        [hasTempBasketBeenInitialized, hasRegularBasketBeenInitialized]
    );

    const basket = useMemo(
        () => (isTempBasketAvailable ? tempBasket : regularBasket),
        [tempBasket, tempBasketType, regularBasket]
    );

    const membershipPoints = useMemo(
        () => (isTempBasketAvailable ? tempMemberPoints : regularMemberPoints),
        [tempMemberPoints, regularMemberPoints]
    );

    const updatingVoucher = useMemo(
        () => (isTempBasketAvailable ? updatingVoucherTempBasket : updatingVoucherRegularBasket),
        [updatingVoucherTempBasket, updatingVoucherRegularBasket]
    );

    const updatingBasket = useMemo(
        () => (isTempBasketAvailable ? updatingTempBasket : updatingRegularBasket),
        [updatingRegularBasket, updatingTempBasket]
    );

    const getMemberPointsForBasket = useCallback(
        (membershipId: string, token?: string) => {
            if (isTempBasketAvailable) {
                getTempMemberPointsForBasket(membershipId, token);
            } else {
                getMemberPointsForRegularBasket(membershipId, token);
            }
        },
        [isTempBasketAvailable]
    );

    const setShipping = useCallback(
        (shippingKey: string) => {
            if (isTempBasketAvailable) {
                setShippingTempBasket(shippingKey);
            } else {
                setShippingRegularBasket(shippingKey);
            }
        },
        [tempBasket, regularBasket, isTempBasketAvailable]
    );

    const setClubMember = useCallback(
        (isMember: boolean) => {
            if (isTempBasketAvailable) {
                setClubMemberTempBasket(isMember);
            } else {
                setClubMemberRegularBasket(isMember);
            }
        },
        [tempBasket, regularBasket, isTempBasketAvailable]
    );

    const setUseMemberPoints = useCallback(
        (membershipNumber: string, token?: string) => {
            if (isTempBasketAvailable) {
                setUseMemberPointsTempBasket(membershipNumber, token);
            } else {
                setUseMemberPointsRegularBasket(membershipNumber, token);
            }
        },
        [tempBasket, regularBasket, isTempBasketAvailable]
    );

    const addVoucher = useCallback(
        (voucherCode: string) => {
            if (isTempBasketAvailable) {
                return addVoucherTempBasket(voucherCode);
            } else {
                return addVoucherRegularBasket(voucherCode);
            }
        },
        [tempBasket, regularBasket, isTempBasketAvailable]
    );
    const clearVoucher = useCallback(() => {
        if (isTempBasketAvailable) {
            clearVoucherTempBasket();
        } else {
            clearVoucherRegularBasket();
        }
    }, [tempBasket, regularBasket, isTempBasketAvailable]);

    return {
        basketId,
        basket,
        tempBasketType,
        tempBasketDeliveryZipCode,
        tempBasketStore,
        isTempBasketAvailable,
        membershipPoints,
        setShipping,
        setClubMember,
        setUseMemberPoints,
        addVoucher,
        clearVoucher,
        getMemberPointsForBasket,
        updatingVoucher,
        hasBeenInitialized,
        updatingBasket,
    };
};
